import React from 'react';
import data from '../../data/contact';

const ContactIcons = () => (
  <ul className="icons align-center">
    {data.map((s) => (
      <li key={s.label}>
        <a href={s.link} aria-label={s.label}>
          {s.icon}
        </a>
      </li>
    ))}
  </ul>
);

export default ContactIcons;
