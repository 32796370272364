import React from 'react';
import { FaAngellist, FaGithub, FaLinkedin } from 'react-icons/fa';
import { RxEnvelopeClosed } from 'react-icons/rx';

const data = [
  {
    link: 'https://github.com/tsnevillecom',
    label: 'Github',
    icon: <FaGithub />,
  },
  {
    link: 'https://www.linkedin.com/in/timothyneville/',
    label: 'LinkedIn',
    icon: <FaLinkedin />,
  },
  {
    link: 'https://wellfound.com/u/tim-neville-2',
    label: 'Wellfound (formerly Angel List)',
    icon: <FaAngellist />,
  },
  {
    link: 'mailto:tsneville@gmail.com',
    label: 'Email',
    icon: <RxEnvelopeClosed />,
  },
];

export default data;
