import React, { Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Main from './layouts/Main';
import './styles/css/main.scss';

const About = lazy(() => import('./routes/About'));
const Contact = lazy(() => import('./routes/Contact'));
const Home = lazy(() => import('./routes/Home'));
const NotFound = lazy(() => import('./routes/NotFound'));
const Portfolio = lazy(() => import('./routes/Portfolio'));
const MultimediaPortfolio = lazy(() => import('./routes/MultimediaPortfolio'));
const Resume = lazy(() => import('./routes/Resume'));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<Main />}>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/marketing-portfolio" element={<MultimediaPortfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
