const routes = [
  {
    index: true,
    label: 'Tim Neville',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'UI/UX Portfolio',
    path: '/portfolio',
  },
  {
    label: 'Marketing Portfolio',
    path: '/marketing-portfolio',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
