/* eslint-disable react/jsx-boolean-value */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter } from '../../components/Modal';
import { ModalContext } from '../../context/ModalContext';
import '../PortfolioModal/PortfolioModal.scss';

const MultimediaPortfolioModal = ({
  title,
  subtitle,
  pieces = [],
  youtubeCode,
}) => {
  const { hideModal } = useContext(ModalContext);

  return (
    <Modal
      closeable={true}
      title={title}
      onClose={hideModal}
      id="portfolio-modal"
    >
      <ModalBody>
        <>
          {subtitle && <h3>{subtitle}</h3>}

          {youtubeCode && (
            <div className="portfolio-video">
              <iframe
                src={`https://www.youtube.com/embed/${youtubeCode}?autoplay=1`}
                frameBorder="0"
                allowFullScreen
                allow="autoplay"
                title={`${title + '_' + subtitle}`}
              />
            </div>
          )}

          <div className="portfolio-list">
            {!!pieces.length &&
              pieces.map((piece) => {
                return (
                  <div className="portfolio-piece" key={piece.file}>
                    <div
                      className="portfolio-image"
                      style={{ width: piece.size ? piece.size : '100%' }}
                    >
                      <img src={piece.file} alt="" />
                    </div>

                    <div className="portfolio-image-description">
                      {piece.description}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      </ModalBody>
      <ModalFooter cancellable={false} onAccept={hideModal} />
    </Modal>
  );
};

MultimediaPortfolioModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  youtubeCode: PropTypes.string,
  pieces: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      size: PropTypes.string,
    })
  ).isRequired,
};

export default MultimediaPortfolioModal;
