import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext({
  hideModal: () => null,
  showModal: () => null,
  modalProps: null,
});

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState(null);

  const showModal = (data) => {
    if (data) setModalProps(data);
  };

  const hideModal = () => {
    setModalProps(null);
  };

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
      modalProps,
    }),
    [modalProps]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};
