/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';
import { IoClose } from 'react-icons/io5';
import classNames from '../../utils/classNames';

const Modal = ({
  children,
  closeable = true,
  blur,
  onClose,
  onLoad,
  onUnload,
  title,
  id,
}) => {
  useEffect(() => {
    const mainLayout = document.getElementById('main-layout');
    if (blur) mainLayout?.classList.add('blur');
    if (onLoad) onLoad();

    return () => {
      mainLayout?.classList.remove('blur');
      if (onUnload) onUnload();
    };
  }, []);

  const modalClasses = classNames({
    modal: true,
    'modal-closeable': closeable,
  });

  const modalHeaderClasses = classNames({
    'modal-header': true,
  });

  const modalBgClasses = classNames({
    'modal-bg': true,
    blur,
  });

  return (
    <div className="modal-overlay">
      <div className={modalClasses} {...(id && { id })}>
        {(title || closeable) && (
          <div className={modalHeaderClasses}>
            {title && <div>{title}</div>}
            {closeable && (
              <div className="modal-close">
                <button
                  className="close"
                  type="button"
                  id="modalClose"
                  onClick={onClose}
                >
                  <IoClose size={24} />
                </button>
              </div>
            )}
          </div>
        )}

        {children}
      </div>

      <div className={modalBgClasses} />
    </div>
  );
};

export const ModalBody = ({
  id,
  align,
  bodyRef,
  center,
  classes,
  children,
}) => {
  const modalBodyClasses = classNames({
    'modal-body': true,
    [align]: !!align,
    [classes]: !!classes,
    center,
  });

  return (
    <div ref={bodyRef} className={modalBodyClasses} {...(id && { id })}>
      {children}
    </div>
  );
};

export const ModalFooter = ({
  acceptable = true,
  cancellable = false,
  disabled = false,
  onAccept = () => null,
  onAcceptText = 'Close',
  onCancel = () => null,
  onCancelText = 'Cancel',
}) => {
  const handleOnAccept = () => {
    if (onAccept) onAccept();
  };

  const modalFooterCx = {
    'modal-footer': true,
  };
  const modalFooterClasses = classNames(modalFooterCx);

  return (
    <div className={modalFooterClasses}>
      {cancellable && (
        <button type="button" id="modalCancel" onClick={onCancel}>
          {onCancelText}
        </button>
      )}

      {acceptable && (
        <button
          type="button"
          id="modalAccept"
          onClick={handleOnAccept}
          disabled={disabled}
        >
          {onAcceptText}
        </button>
      )}
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  closeable: PropTypes.bool,
  blur: PropTypes.bool,
  onClose: PropTypes.func,
  onLoad: PropTypes.func,
  onUnload: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
};

ModalBody.propTypes = {
  id: PropTypes.string,
  align: PropTypes.bool,
  bodyRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  center: PropTypes.bool,
  classes: PropTypes.string,
  children: PropTypes.node,
};

ModalFooter.propTypes = {
  acceptable: PropTypes.bool,
  cancellable: PropTypes.bool,
  disabled: PropTypes.bool,
  onAccept: PropTypes.func,
  onAcceptText: PropTypes.string,
  onCancel: PropTypes.func,
  onCancelText: PropTypes.string,
};

export default Modal;
