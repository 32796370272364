import React, { useContext } from 'react';
import { ModalContext } from '../context/ModalContext';

// MODALS
import PortfolioModal from './PortfolioModal';
import MultimediaPortfolioModal from './MultimediaPortfolioModal';

const MODALS_MAP = {
  PORTFOLIO_MODAL: PortfolioModal,
  MULTIMEDIA_PORTFOLIO_MODAL: MultimediaPortfolioModal,
};

const ModalRoot = () => {
  const { modalProps } = useContext(ModalContext);

  if (!modalProps) return null;
  const Modal = MODALS_MAP[modalProps.name];
  return <Modal {...modalProps.data} />;
};

export default ModalRoot;
